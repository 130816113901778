/*:root {*/
/*  --primary-bg-color: #282c34;*/
/*  --primary-menu-title-color:#a36b53;*/
/*  --primary-menu-bg-color: #1d1d1d;*/
/*  --primary-text-color: ;*/
/*  --primary-link-color: #61dafb;*/
/*  --primary-button-bg-color: #a36b53;*/
/*  --primary-button-text-color: #eee;*/
/*  --primary-button-hover-color: #6a412f;*/
/*  --primary-content-bg-color: ;*/
/*}*/




:root {
  --primary-bg-color: #ebd7fa;
  --primary-menu-title-color: #C0C0C0FF;
  --primary-content-bg-color: #1a202c;
  --primary-text-color: #C0C0C0FF;
  --primary-link-color: #413c3c;
  --primary-button-bg-color: #301c4d;
  --primary-button-text-color: #C0C0C0FF;
  --primary-button-hover-color:	#431c76;
  --primary-menu-bg-color: #141414;
  --primary-button-border-color: 	#431c76;
  --primary-card-bg-color: #2D3748;
}

.App {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .ant-modal {
    position: static !important;
  }
}

@media screen and (max-width: 768px) {
  .ant-modal-body {
    padding: 0.5rem 2rem !important;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primary-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--primary-text-color);
}

.App-link {
  color: var(--primary-link-color);
}

.ant-btn {
  background-color: var(--primary-button-bg-color) !important;
  color: var(--primary-button-text-color) !important;
  border: 1px solid var(--primary-button-border-color) !important;
}

.ant-btn:hover {
  background-color: var(--primary-button-hover-color) !important;
  border: 1px solid var(--primary-button-border-color) !important;
}

.ant-btn:focus {
  background-color: var(--primary-button-hover-color) !important;
  border: 1px solid var(--primary-button-border-color) !important;
}

.ant-input-search-button {
  background-color: var(--primary-button-bg-color) !important;
  border: none !important;
}

.ant-input-search-button:hover {
  background-color: var(--primary-button-hover-color);
}

.ant-input-search-button:focus {
  background-color: var(--primary-button-hover-color);
}
.ant-segmented-item-label{
  white-space: break-spaces !important;
}
.ant-segmented-item-selected{
  background-color: var(--primary-button-bg-color) !important;
  border-color: var(--primary-button-bg-color) !important;
  color: var(--primary-button-text-color) !important;
}
.ant-segmented-item-selected:hover{
  background-color: var(--primary-button-hover-color) !important;
  border-color: var(--primary-button-hover-color) !important;
  color: var(--primary-button-text-color) !important;
}
.ant-segmented-item-selected:focus{
  background-color: var(--primary-button-hover-color) !important;
  border-color: var(--primary-button-hover-color) !important;
  color: var(--primary-button-text-color) !important;
}
.ant-list-split .ant-list-item {
   border-bottom: 0px !important;
}

.ant-btn-ghost {
  color: rgba(0, 0, 0, .85) !important;
  border-color: #d9d9d9 !important;
  background: transparent !important;
}

.ant-btn-ghost:hover {
  color: rgba(0, 0, 0, .85) !important;
  border-color: #d9d9d9 !important;
  background: silver !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-layout-header {
    padding: 0 0 !important;
}

.ant-layout-content {
    padding: 0 50px !important;
    background-color: var(--primary-content-bg-color) !important;

    @media screen and (max-width: 476px) {
        padding: 0 10px !important;
    }
}

.ant-layout-footer {
  background-color: var(--primary-content-bg-color) !important;
  color: var(--primary-menu-title-color) !important;
}

.ant-menu-title-content{
    color: var(--primary-menu-title-color) !important;
}
.ant-menu-dark{
    background-color: var(--primary-menu-bg-color) !important;
}
.ant-menu-item{
    background-color: var(--primary-menu-bg-color) !important;
}

.ant-drawer-body{
  background-color: var(--primary-menu-bg-color) !important;
}

.ant-typography{
    color: var(--primary-text-color) !important;
}

.ant-list-item {
  color: var(--primary-text-color) !important;
}

.ant-divider-inner-text{
    color: var(--primary-text-color) !important;
}
.ant-form-item-label>label{
  color: var(--primary-text-color) !important;
}

.ant-card{
  background-color: var(--primary-card-bg-color) !important;
}

.ant-checkbox-inner{
  border-color: var(--primary-text-color) !important;
}


@media print {
  @page {
    size: landscape;
  }

  #print .ant-table {
    width: 100%;
    table-layout: fixed;
  }

  #print .ant-table th, #print .ant-table td {
    padding: 8px;
    font-size: 0.8em;
  }
  .category-1d {
    background-color: #0b538d;
  }

  .category-2d {
    background-color: #f58597;
  }

  .category-3d {
    background-color: #575705;
  }

  .category-4d {
    background-color: #087308;
  }

  .category-junior {
    background-color: #0b538d;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}